/**
 * Created by amine on 08/09/2017.
 */
(function () {

    'use strict';

    module.exports = {
        controller: FillCustomVarFormDialogController,
        controllerAs: "vm",
        template: require("../views/fill-custom-var.tpl.html"),
        parent: $(document.body),
        bindToController: true,
        clickOutsideToClose: true
    };

    FillCustomVarFormDialogController.$inject = ["$mdDialog"];

    function FillCustomVarFormDialogController($mdDialog) {
        let vm = this;

        vm.$onInit = init;
        vm.submit = submit;
        vm.cancel = $mdDialog.cancel;

        function init() {
            vm.data = {};
        }

        function submit() {
            $mdDialog.hide(vm.data);
        }

    }

})();
