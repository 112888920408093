/**
 * Created by amine on 28/07/2018.
 */

(function () {
    "use strict";

    const {BehaviorSubject} = require("rxjs");

    class TemplatesPlugin {

        constructor(options, $translate, $mdDialog, $q, editorTemplateService) {
            this.templateButtonElem = null;
            this.templates = new BehaviorSubject([]);
            this.deferred = $q.defer();

            this.options = options;
            this.$translate = $translate;
            this.$mdDialog = $mdDialog;
            this.$q = $q;
            this.editorTemplateService = editorTemplateService;

            this.getTemplates()
                .then(data => {
                    tinymce.PluginManager.add("templates_plugin", editor => {
                        editor.addButton("templates", this.generateMenu(data, editor));
                    });
                    this.deferred.resolve(true);
                });
        }

        refreshTemplates(editor) {
            this.getTemplates()
                .then(data => {
                    const parent = this.templateButtonElem.parent();
                    this.templateButtonElem.remove();
                    parent._lastRepaintRect = parent._layoutRect;
                    parent.append(this.generateMenu(data, editor));
                });
        }

        asyncLoad() {
            return this.deferred.promise
        }

        generateMenu(data, editor) {
            let menu = [{
                text: this.$translate.instant("editor_add_custom_models"),
                onclick: () => this.newTemplate(editor)
            }, {
                text: '|'
            }];

            data.forEach(template => {
                menu.push({
                    text: template.title,
                    onclick: () => this.insertTemplate(editor, template)
                })
            })

            return {
                tooltip: this.$translate.instant("editor_custom_models"),
                onclick: () => this.manageTemplates(editor),
                type: 'splitbutton',
                classes: "templates",
                icon: "templates",
                menu: menu,
                onpostrender: (event) => {
                    this.templateButtonElem = event.target;
                }
            };
        }

        manageTemplates(editor) {
            console.log(editor);
        }

        getTemplates() {
            const promise = this.editorTemplateService
                .getTemplates();

            promise.then(data => this.templates.next(data))

            return promise;
        }

        newTemplate(editor) {
            if (_.isEmpty(editor.getContent())) return false;

            let confirm = this.$mdDialog.mnPromptDialog()
                .title('exam_model_title')
                .event('shared.EditorModel.title_validation')
                .placeholder('title')
                .targetEvent(null);

            this.$mdDialog.show(confirm).then(title => {
                this.editorTemplateService.saveTemplate({
                    title: title,
                    content: editor.getContent()
                }).then(() => this.refreshTemplates(editor));
            })
        }

        insertTemplate(editor, template) {
            editor.insertContent(template.content);
        }
    }

    // function TemplatesPlugin() {
    //     tinymce.PluginManager.add("templates_plugin", function (editor) {
    //
    //         editor.addButton("templates", {
    //             tooltip: "Gestion de templates",
    //             onclick: _blank_line,
    //             type: 'splitbutton',
    //             classes: "templates",
    //             menu: [{
    //                 text: 'Menu item 1',
    //                 onclick: function () {
    //
    //                 }
    //             }, {
    //                 text: '|'
    //             }]
    //         });
    //
    //         // editor.addButton(menu.title + "_menu", {
    //         //     text: $translate['instant'](menu.title),
    //         //     tooltip: $translate['instant'](menu.title),
    //         //     type: 'menubutton',
    //         //     inserted: inserted,
    //         //     menu: menuItems
    //         // });
    //
    //         function _blank_line() {
    //             console.log(25);
    //         }
    //     });
    // }

    module.exports = TemplatesPlugin;
})()
