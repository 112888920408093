/**
 * Created by amine on 08/09/2017.
 */

(function () {
    "use strict";

    module.exports = dataInjectorPlugin;

    function dataInjectorPlugin(editorOptions, $translate, $mdDialog, $q, _getKey) {
        tinymce.PluginManager.add("data_injector_plugin", function (editor) {
            const dialog = require("../dialogs/custom-var");

            let menus = _.isUndefined(editor.settings.data_injector_buttons) ? [] : editor.settings.data_injector_buttons;
            this.compile = _.bind(_compile, editor);
            this.compileContent = _.bind(_compileContent, editor);
            this.handleMnVarClick = _.bind(_handleMnVarClick, editor);
            this.addMenus = _.bind(_addMenus, editor);

            editor.addButton("blank_line", {
                tooltip: $translate.instant("editor_blank_line"),
                onclick: _blank_line,
                classes: "blank_line_btn"
            });

            editor.addButton("compile_content", {
                tooltip: $translate.instant("editor_compile_content"),
                onclick: _compileContent,
                classes: "compile_content_btn"
            });

            editor.addButton("custom_variable", {
                tooltip: $translate.instant("editor_custom_variable"),
                onclick: _addCustomVar,
                classes: "custom_var_btn"
            });

            _.forEach(menus, _.bind(generateMenu, editor));

            function findItem(key) {
                function _findItem(result, menu) {
                    let item = _.find(menu.items, {key: key});
                    if (!_.isNil(item)) {
                        _.assign(result, item);
                        return false;
                    }
                }

                return _.transform(menus, _findItem, {});
            }

            function _compileVars(errors, key) {
                let $body = this;
                let item = findItem(key);
                let value = _getValue(item);
                let $mnVar = $body.find(`mn-var[key='${key}'][dirty=false]`).not(".custom");

                if (_.isNil(value)) {
                    $mnVar.addClass("error");
                    errors.push(item);
                } else {
                    $mnVar.each(function () {
                        let content = $(this).html();
                        $(this).replaceWith(
                            $("<mn-var />")
                                .html(_.replace(content, new RegExp(`%${key}%`, "g"), value))
                                .attr("key", key)
                                .attr("dirty", true)
                                .addClass("e")
                        );
                    });
                }

                return errors;
            }

            function _compile(content) {
                let deferred = $q.defer();
                let $body = $(editor.getBody());

                if (_.isNaN(content) || _.isNil(content) || _.isEmpty(content)) {
                    setTimeout(function () {
                        deferred.reject();
                    }, 500)
                } else {
                    _compileCustomVars($body).then(success);
                }


                return deferred.promise;

                function success() {
                    let errors = _($("mn-var[dirty=false]", $body).not(".custom"))
                        .jqInvoke("attr", "key")
                        .uniq()
                        .reduce(_.bind(_compileVars, $body), []);

                    deferred.resolve($body.html())
                }
            }

            function _compileCustomVars($body) {
                let deferred = $q.defer();
                const dialog = require("../dialogs/fill-custom-var");

                let metas = _($("mn-var.custom[dirty=false]", $body))
                    .jqInvoke("attr", "meta")
                    .uniq()
                    .map(JSON.parse)
                    .value();

                if (_.isEmpty(metas)) deferred.resolve(false);
                else $mdDialog.show(_.assign(dialog, {
                    targetEvent: jQuery.Event("click"),
                    locals: {metas: metas}
                })).then(success, deferred.resolve);

                function success(data) {
                    let arr = _($("mn-var.custom[dirty=false]", $body))
                        .jqInvoke("attr", "key")
                        .uniq()
                        .map(_.bind(_compileSingleCustomVar, $body, data))
                        .value();

                    deferred.resolve(true);
                }

                return deferred.promise;
            }

            function _compileSingleCustomVar(data, key) {
                let $body = this;
                let $mnVar = $body.find("mn-var.custom[key='" + key + "'][dirty=false]");

                $mnVar.each(function () {
                    let str_meta = $(this).attr("meta");
                    let meta = JSON.parse(str_meta);
                    let value = _.isNil(data[meta.slug]) ? "" : data[meta.slug]

                    if (meta.type === "longtext" || meta.type === "list_longtext") value = value.replace(/(?:\r\n|\r|\n)/g, '<br />');
                    if (meta.type === "datetime") value = `${value.date} ${value.time}`;

                    let content = $(this).html();
                    $(this).replaceWith(
                        $("<mn-var />")
                            .html(_.replace(content, new RegExp(`%${key}%`, "g"), value))
                            .attr("key", key)
                            .attr("dirty", true)
                            .addClass("e")
                    );
                });
            }

            function _blank_line() {
                editor.insertContent("<mn-nl />");
            }

            function _compileContent() {
                return _compile(editor.getContent() ? editor.getContent() : "")
                    .then((html) => {
                        editor.setContent(html);
                        editor.fire("keyup");
                    }, _.noop);
            }

            function _handleMnVarClick() {
                let editor = this;
                const dom = editor.iframeElement.contentDocument;
                $(dom).on("click", "mn-var.ne", function () {
                    if (dom.getSelection && dom.createRange) {
                        let $mnVar = $(this);
                        let $parent = $mnVar.parent(".mn-var-style");
                        if ($parent.length === 0) {
                            $mnVar.wrap($("<span class='mn-var-style' />"));
                            $parent = $mnVar.parent(".mn-var-style");
                        }

                        editor.selection.select($parent[0]);
                    }
                });
                $(dom).on("dblclick", "mn-var.ne.custom", function (event) {
                    event.stopPropagation();
                    let str_meta = $(this).attr("meta");

                    $mdDialog
                        .show(_.assign(_.cloneDeep(dialog), {
                            locals: {meta: JSON.parse(str_meta), selectedTab: 1},
                        }))
                        .then(getCustomVarMetaData)
                });
            }

            function _addCustomVar(event) {
                $mdDialog.show(_.assign(dialog, {targetEvent: event, locals: {}}))
                    .then(getCustomVarMetaData)
            }

            function getCustomVarMetaData(meta) {
                let wrap = _.template("<mn-var key='{{key}}' meta='{{meta}}' class='custom ne'>{{content}}</mn-var>&nbsp;", {interpolate: /{{([\s\S]+?)}}/g});
                editor.execCommand("mceInsertContent", false, wrap({
                    content: _getKey(meta),
                    key: meta.key,
                    meta: JSON.stringify(meta)
                }));

                editor.fire("keyup");
            }

            function _addMenus(_menus) {
                let editor = this;
                menus = _.concat(menus, _menus);
                _.forEach(_menus, function (menu) {
                    let menuName = _.bind(generateMenu, editor)(menu, true);

                    let button = editor.buttons[menuName];
                    let bg = editor.theme.panel.find('toolbar buttongroup')[0];
                    bg._lastRepaintRect = bg._layoutRect;
                    bg.append(button);
                });
            }

            function generateMenu(menu, inserted) {
                let editor = this;

                inserted = _.isUndefined(inserted) ? false : inserted;

                editor.addButton(menu.title + "_menu", {
                    text: $translate.instant(menu.title),
                    tooltip: $translate.instant(menu.title),
                    type: 'menubutton',
                    inserted: inserted,
                    menu: generateMenus(menu.items)
                });


                return `${menu.title}_menu`;

                function itemClick() {
                    let wrap = _.template("<mn-var key='{{key}}'>{{content}}</mn-var>&nbsp;", {interpolate: /{{([\s\S]+?)}}/g});
                    if (editorOptions["auto_compile"]) {
                        editor.execCommand("mceInsertContent", false, wrap({
                            content: _getValue(this),
                            key: this.key
                        }));
                    } else {
                        editor.execCommand("mceInsertContent", false, wrap({
                            content: _getKey(this, editor),
                            key: this.key
                        }));
                    }

                    editor.fire("keyup");
                }

                // this function only for
                function imageItemClick() {
                    let wrap = _.template("<img src='{{src}}' alt='IMAGE'/>&nbsp;", {interpolate: /{{([\s\S]+?)}}/g});

                    editor.execCommand("mceInsertContent", false, wrap({
                        src: _getValue(this)
                    }));

                    editor.fire("keyup");
                }

                // function to generate the menus also support submenus as well
                function generateMenus(items) {
                    return _.map(items, item => {
                        const text = $translate.instant(item.title);

                        return item.items ? {text, menu: generateMenus(item.items)} : {
                            text, onclick: _.bind(item.isImage ? imageItemClick : itemClick, item)
                        }
                    })
                }
            }

            function _getValue(item) {
                try {
                    if (_.isFunction(item.value)) {
                        return item.value();
                    } else {
                        return _getKey(item, editor);
                    }
                } catch (e) {
                    return false;
                }
            }
        });

    }
})();
