(function () {

    'use strict';

    const XLSX = require('xlsx');
    const MenuCtrl = require('shared/utils/menu-ctrl');
    const ENTRY_DIALOG = require('frontdesk/dialogs/entry-dialog');
    const VISIT_SUB_LINKS = require('parameters/json/visit-sub-links.json');
    const APPOINTMENT_DIALOG = require('frontdesk/dialogs/appointment-dialog');

    const MENU_TPL = `
        <div class="calendar-navigation-panel md-whiteframe-z2">
            <md-calendar ng-model="vm.currentDate" ng-change="vm.refreshDate()"></md-calendar>
        </div>
    `;

    class DailyWorkflowCtrl {
        constructor(
            $mdPanel, $scope, $compile, moment, WorkFlowService, $state, frontDeskService, paymentService, mnWebSocket,
            visitService, system, $q, configService, practiceService, $mdDialog, $element, $translate
        ) {
            this.$q = $q;
            this.state = $state;
            this.moment = moment;
            this.ws = mnWebSocket;
            this.dialog = $mdDialog;
            this.element = $element;
            this.translate = $translate;
            this.service = WorkFlowService;
            this.visitService = visitService;
            this.configService = configService;
            this.practiceService = practiceService;
            this.frontDeskService = frontDeskService;

            this.dateFormat = system['date_format'].js;
            this.timeFormat = system['time_format'].js;
            this.menuCtrl = new MenuCtrl($mdPanel, $scope, $compile);

            let currentMoment = this.moment();
            this.updateDate(currentMoment);

            this.filters = {};
            this.dateTimeFormat = system['date_format'].naive;
            this.decideMethod = frontDeskService.decideMethod;
            this.editEvent = frontDeskService.editAppointment;
            this.payVisit = (ev, item) => paymentService.payEntry(ev, item);

            this.refreshData = _.mnDelay(() => this.refreshDataCall(), 200);

            this.callbacks = {
                patientOut: (item) => this.patientOut(item),
                patientFile: item => this.patientFile(item),
                editEntry: (item, ev) => this.editEntry(item, ev),
                payVisit: (ev, item) => this.payVisit(ev, item),
                prePayVisit: (ev, item) => paymentService.prePayEntry(ev, item),
                payTreatmentPlans: (ev, item) => paymentService.payDentalTreatmentPlan(ev, _.get(item, "patient.id"))
            };

            this.views = [];
            this.selectedView = {};
            this.columns = this.service.getColumnConfiguration();
        }

        static get $inject() {
            return [
                '$mdPanel', '$scope', '$compile', 'moment', 'WorkFlowService', '$state', 'frontDeskService',
                'paymentService', 'mnWebSocket', 'visitService', 'system', '$q', 'configService', 'practiceService',
                '$mdDialog', "$element", "$translate"
            ];
        }

        $onInit() {
            this.refreshData();
            this.subscription = this.practiceService.entriesSubject.subscribe(() => this.refreshData());
            this.visitSubLinksSubscription = this.visitService.visitSubLinks.subscribe(
                data => {
                    this.visitLinkConfig = _.find(VISIT_SUB_LINKS, ['key', data['favorite']]);
                    this.generateVisit = this.callbacks.generateVisit = (entry, state) => {
                        return this.frontDeskService.generateVisit(entry, state ? state : this.visitLinkConfig.link)
                    }
                }
            );

            this.ws.sub("frontdesk.Practice.entry_added", 'daily-workflow', () => this.refreshData());
            this.ws.sub("frontdesk.Practice.entry_canceled", 'daily-workflow', () => this.refreshData());
            this.ws.sub("frontdesk.Practice.entry_updated", 'daily-workflow', () => this.refreshData());
            this.ws.sub("frontdesk.Practice.stat_changed", 'daily-workflow', () => this.refreshData());
            this.ws.sub("frontdesk.Calendar.notify", 'daily-workflow', () => this.refreshData());

            this.promise = this.$q.all([this.service.getWorkflowViews(), this.configService.get(['work_flow_config', 'calendar_config'])])
                .then(data => {
                    this.config = _.get(data[1], 'work_flow_config', {default: null});
                    this.dayFilter = _.get(data[1], 'calendar_config.practice_filter', true);

                    this.views = _.filter(data[0], item => !_.get(this.config, `disabled.${item.id}`, false));
                    this.selectView(_.isNil(this.config.default) ? this.views[0] : _.find(this.views, ["id", parseInt(this.config.default)]));
                });
        }

        $onDestroy() {
            if (this.subscription) this.subscription.unsubscribe();
            if (this.visitSubLinksSubscription) this.visitSubLinksSubscription.unsubscribe();

            this.ws.unsub("frontdesk.Practice.entry_added", 'daily-workflow');
            this.ws.unsub("frontdesk.Practice.entry_canceled", 'daily-workflow');
            this.ws.unsub("frontdesk.Practice.entry_updated", 'daily-workflow');
            this.ws.unsub("frontdesk.Practice.stat_changed", 'daily-workflow');
            this.ws.unsub("frontdesk.Calendar.notify", 'daily-workflow');
        }

        addAppointment(ev) {
            this.dialog.show(_.assign({}, APPOINTMENT_DIALOG, {
                multiple: true,
                targetEvent: ev,
                locals: {
                    event: {
                        start_time: this.moment().format(this.timeFormat),
                        date: this.moment(this.currentDate).format(this.dateFormat),
                    },
                    patient: null,
                    hasPause: false,
                }
            }));
        }

        addEntry(ev) {
            this.dialog.show(_.assign({}, ENTRY_DIALOG, {
                targetEvent: ev, locals: {entryTmp: null}
            })).then(data => {
                this.refreshData();
                this.practiceService.entryAdded(data);
            });
        }


        countColumns() {
            return this.service.columnConfiguration.length - _.chain(this.selectedView).get('disabled_as_dict', {}).values().compact().size().add(-1).value();
        }

        refreshDataCall() {
            this.promise = this.service.getWorkFlow(this.currentDate)
                .then(data => {
                    this.items = data;
                    this.filterItems();
                });
        }

        openCalendar(ev) {
            this.menuCtrl.open(ev, MENU_TPL).then(ref => this.panelRef = ref);
        }

        today() {
            let date = this.moment();
            this.updateDate(date);

            this.refreshData();
        }

        isToday() {
            let dateMoment = this.moment(this.currentDate);
            return this.moment().isSame(dateMoment, 'day');
        }

        nextDate() {
            let date = this.moment(this.currentDate).add(1, 'days');
            this.updateDate(date);

            this.refreshData();
        }

        previousDate() {
            let date = this.moment(this.currentDate).add(-1, 'days');
            this.updateDate(date);

            this.refreshData();
        }

        refreshDate() {
            this.panelRef && this.panelRef.hide();

            let dateMoment = this.moment(this.currentDate);
            this.updateDate(dateMoment);

            this.refreshData();
        }

        updateDate(momentDate) {
            this.currentDate = momentDate.toDate();
            this.currentDateText = _.capitalize(momentDate.format('dddd LL'));
        }

        // filters
        handleFilter(data, isAll) {
            this.filters = isAll ? _.omit(this.filters, _.keys(data)) : _.assign(this.filters, data);
            this.filterItems();
        }

        filterItems() {
            let list;

            if (_.isEmpty(this.filters)) list = this.items;

            else list = _.filter(this.items, (item) => {
                return _.reduce(this.filters, (test, value, key) => test && _.includes(value, _.get(item, key)), true);
            });

            this.handledItems = this.service.orderData(list);
        }

        editEntry(item, ev) {
            this.frontDeskService.editEntry(item, ev)
                .then(data => {
                    const entry = data.entry ? data.entry : data;
                    this.practiceService.entryUpdated(entry);

                    this.refreshData();
                });
        }

        patientFile(item) {
            return this.state.go("app.patient-form", {'patient_id': item.patient_id});
        }

        patientOut(item, source) {
            this.frontDeskService.updateEntry(item.id, source, 'ex')
                .then(() => this.ws.pub("frontdesk.Practice.entry_count", {}, false));
        }

        entryResume(item, ev) {
            const isEntry = item.stat !== 'RDV';
            this.frontDeskService.entryResume({
                onlyResume: !isEntry,
                entry: isEntry ? item : null,
                'patient-id': item.patient_id
            }, ev);
        }

        // copied from practice menu (for now)
        isEntryToday(item) {
            return this.moment(item.entry_time).format(this.dateFormat) === this.moment().format(this.dateFormat);
        }

        startNewEntry(item, stat) {
            return this.frontDeskService.checkPatientEntry(item.patient.id)
                .then(() => this.newEntrySuccess(item, stat));
        }

        newEntrySuccess(item, stat) {
            this.frontDeskService.reopenEntry(item, stat)
                .then(data => {
                    this.practiceService.entryAdded(data);
                    this.ws.pub("frontdesk.Practice.entry_added", data);
                });
        }

        // export to excel
        exportToExcel() {
            let $elt = $('.workflow-table', this.element).clone(true);
            let translatedLabel = this.translate.instant(
                'daily_workflow.excel_file_name', {date: this.moment(this.currentDate).format(this.dateFormat)}
            );

            $(document.body).append($elt);
            $('.actions-column-4, .action-cell, mn-patient-alert, .md-table-progress', $elt).remove();

            let label = _.chain(translatedLabel)
                .deburr()
                .replace(/[^a-z0-9]/gi, '_')
                .truncate()
                .value();

            let wb = XLSX.utils.table_to_book($elt.get(0), {sheet: label, raw: false});
            let file = XLSX.writeFile(wb, `${label}.xlsx`);

            $elt.remove();

            return file;
        }

        selectView(view) {
            this.selectedView = view;

            this.columns = _.chain(this.service.getColumnConfiguration())
                .filter(item => !_.get(this.selectedView, `disabled_as_dict.${item.key}`, true)) // if not found should be removed
                .orderBy(item => _.findIndex(this.selectedView.columns, ["key", item.key]))
                .value();
        }
    }

    module.exports = DailyWorkflowCtrl;

})();
