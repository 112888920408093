/**
 * Created by amine on 08/09/2017.
 */
(function () {

    'use strict';

    const INPUT_TYPES = [
        {name: "text"},
        {name: "longtext"},
        {name: "date"},
        {name: "datetime"},
        {name: "integer"},
        {name: "float"},
        {name: "list", params: true, data: [null]},
        {name: "list_longtext", params: true, data: [{title: null, content: null}]}
    ];

    class CustomVarFormCtrl {
        constructor($mdDialog, customVariableService, $scope) {
            this.dialog = $mdDialog;
            this.service = customVariableService;
            this.scope = $scope;

            this.inputTypes = INPUT_TYPES;
            this.params = false;
            this.reset = null;
            this.promise = null;
            this.actions = {
                single: [
                    {
                        icon: 'mdi-pencil',
                        label: 'edition',
                        resource: 'general-config',
                        action: 'update',
                        behavior: 'disable',
                        method: (meta, event) => this.getVariable(true, meta, event)
                    },
                    {
                        icon: 'mdi-check',
                        label: 'use',
                        resource: 'general-config',
                        action: 'get',
                        behavior: 'disable',
                        method: (meta, event) => this.getVariable(false, meta, event)
                    }
                ],
                multiple: []
            };

        }

        static get $inject() {
            return ["$mdDialog", "customVariableService", "$scope"];
        }

        $onInit() {
            this.meta = _.assign({id: null, type: "text"}, this.meta || {});
            this.type = _.find(INPUT_TYPES, {name: this.meta.type});

            this.params = _.get(this.type, "params", false);
            this.meta.data = _.get(this.meta, "data", null);

            const $unwatch = this.scope.$watch("vm.reset", (newValue) => {
                if (newValue) {
                    this.reset({search: {}}, false);
                    $unwatch();
                }
            });
            // setInterval(() => console.log(this.reset), 500);
        }

        cancel() {
            this.dialog.cancel();
        }

        labelChanged() {
            this.meta.slug = _.kebabCase(this.meta.label);
        }

        typeChanged() {
            const type = _.find(INPUT_TYPES, {name: this.meta.type});

            this.params = _.get(type, "params", false);
            this.meta.data = _.get(type, "data", null);
        }

        getVariable(edit, meta, event) {
            event.stopPropagation();

            this.meta = meta;
            if (!edit) this.submit();
            else this.selectedTab = 2;
        }

        prepareMeta() {
            this.meta.key = `custom::${this.meta.slug}`;
        }

        submit() {
            this.prepareMeta();
            this.dialog.hide(this.meta);
        }

        saveVariable(validate) {
            this.prepareMeta();
            this.reset();
            this.selectedTab = 0;
            this.service.saveVariable(this.meta)
                .then(() => {
                    if (validate) this.submit();
                    else {
                        this.meta = _.assign({
                            id: null,
                            type: "text"
                        }, {});

                        this.scope.customVarForm.$setPristine();
                    }
                });

        }

        addNewToList(obj) {
            this.meta.data.push(obj);
        }

        removeFromList($index) {
            this.meta.data.splice($index, 1);
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: CustomVarFormCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("../views/custom-var.tpl.html"),
    };
})();

/*
*
*
    CustomVarFormDialogController.$inject = ["$scope", "$mdDialog", "customVariableService"];

    function CustomVarFormDialogController($scope, $mdDialog, customVariableService) {
        var vm = this;

        vm.$onInit = init;
        vm.submit = submit;
        vm.typeChanged = typeChanged;
        vm.addNewToList = addNewToList;
        vm.removeFromList = removeFromList;
        vm.labelChanged = labelChanged;
        vm.saveVariable = saveVariable;
        vm.selectRow = getVariable(false);
        vm.cancel = $mdDialog.cancel;

        function init() {
            vm.params = false;
            vm.inputTypes = [
                {name: "text"},
                {name: "longtext"},
                {name: "date"},
                {name: "datetime"},
                {name: "integer"},
                {name: "float"},
                {name: "list", params: true, data: [null]},
                {name: "list_longtext", params: true, data: [{title: null, content: null}]}
            ];

            vm.meta = _.assign({
                id: null,
                type: "text"
            }, vm.meta);

            var type = _.find(vm.inputTypes, {name: vm.meta.type});

            vm.params = _.get(type, "params", false);
            vm.meta.data = _.get(vm.meta, "data", null);

            vm.actions = {
                single: [
                    {
                        icon: 'mdi-pencil',
                        label: 'edition',
                        resource: 'general-config',
                        action: 'update',
                        behavior: 'disable',
                        method: getVariable(true)
                    },
                    {
                        icon: 'mdi-check',
                        label: 'use',
                        resource: 'general-config',
                        action: 'get',
                        behavior: 'disable',
                        method: getVariable(false)
                    }
                ],
                multiple: []
            };
        }

        function labelChanged() {
            vm.meta.slug = _.kebabCase(vm.meta.label);
        }

        function typeChanged() {
            var type = _.find(vm.inputTypes, {name: vm.meta.type});

            vm.params = _.get(type, "params", false);
            vm.meta.data = _.get(type, "data", null);
        }

        function getVariable(edit) {
            return function (meta, event) {
                event.stopPropagation();

                vm.meta = meta;
                if (!edit) submit();
                else vm.selectedTab = 2;
            }
        }

        function prepareMeta() {
            vm.meta.key = _.format("custom::{0}", vm.meta.slug);
        }

        function submit() {
            prepareMeta();
            $mdDialog.hide(vm.meta);
        }

        function saveVariable(validate) {
            prepareMeta();
            vm.reset()
            vm.selectedTab = 0;
            customVariableService
                .saveVariable(vm.meta)
                .then(success)

            function success() {
                if (validate) submit();
                else {
                    vm.meta = _.assign({
                        id: null,
                        type: "text"
                    }, {});

                    $scope.customVarForm.$setPristine();
                }
            }

        }

        function addNewToList(obj) {
            vm.meta.data.push(obj);
        }

        function removeFromList($index) {
            vm.meta.data.splice($index, 1);
        }
    }
* */
