(function () {

    "use strict";

    const SpeechRecognition = require('../utils/speech-recognition');

    module.exports = speechPlugin;

    function speechPlugin(option, $translate) {
        tinymce.PluginManager.add("editor_speech_plugin", editorCallback);

        function editorCallback(editor) {
            let recognition = new SpeechRecognition();

            if (recognition.recognition) editor.addButton("editor_speech", {
                classes: "speech_btn",
                onclick: _speechContent,
                tooltip: $translate['instant']("editor_speech"),
            });

            let $speechDiv = $("<div class='editor-speech-div layout-row layout-align-center-center' >")
                .append('<span class="mdi mdi-microphone">');


            function _speechContent() {
                let $body = $(editor.getBody());

                editor.on('FullscreenStateChanged', fullScreenChange);
                editor.execCommand('mceFullScreen');

                function fullScreenChange(e) {
                    if (e.state) {
                        $('.mce-container.mce-edit-area', editor['editorContainer']).append($speechDiv);
                        recognition.start();
                        recognition.subject.subscribe(updateContent);
                    }

                    else {
                        recognition.stop();
                        $('.editor-speech-div', editor['editorContainer']).remove();
                        $(".speech", $body).addClass('valid-speech').removeClass('ne speech');
                    }
                }

                function updateContent(results) {
                    let final = _.get(results, "results.0.isFinal");
                    let transcript = _.get(results, "results.0.0.transcript") + (final ? " " : "");
                    let span = $("<span / >").append(transcript).addClass(final ? "valid-speech" : "ne speech");

                    if ($(".speech", $body).length > 0) $(".speech", $body).replaceWith(span);
                    else editor.execCommand("mceInsertContent", false, span.prop('outerHTML'));

                    editor.fire("keyup");
                }
            }
        }
    }

})()