/**
 * Created by Amine on 26/08/2016. [edited by Amine on 17/10/2019]
 */
(function () {

    'use strict';

    class TextEditorCtrl {
        constructor($scope, $element, textEditorService) {
            this.$scope = $scope;
            this.$element = $element;
            this.textEditorService = textEditorService;

            this.ngModelController = this.ngModelController || null;
            this.label = this.label || null;
            this.labelColor = this.labelColor || null;
            this.minimal = this.minimal || null;
            this.configKey = this.configKey || null;
            this.config = {};
        }

        static get $inject() {
            return ["$scope", "$element", "textEditorService"];
        }

        $onInit() {
            if (_.isNil(this.options)) this.options = {};
            if (_.isNil(this.name)) this.name = _.uniqueId("mce__");

            if (this.label) this.$element.addClass("with-label");
            if (this.minimal) this.$element.addClass("minimal");

            $("textarea", this.$element).attr("id", this.name);

            this.config.onSetup = _.noop;
            this.options['minimal'] = this.minimal;
            this.config.selector = `textarea#${this.name}`;

            this.textEditorService
                .initTinyMCE(this.configKey, this.options, this.config)
                .then(editor => this.editorInitialized(editor));
        }

        $onDestroy() {
            const editor = tinymce.get(this.name);
            if (!_.isNil(editor)) editor.destroy();
        }

        $onChanges(changes) {
            let editor = tinymce.get(this.name);
            if (editor && changes.readonly) {
                this.editorReadonly(editor, changes.readonly.currentValue);
                if (changes.readonly.currentValue) {
                    editor.setMode('readonly');
                    editor.theme.panel.find("toolbar").hide();
                } else {
                    editor.setMode('design');
                    editor.theme.panel.find("toolbar").show();
                }
            }
        }

        editorReadonly(editor, readonly) {
            if (readonly) {
                editor.setMode('readonly');
                editor.theme.panel.find("toolbar").hide();
            } else {
                editor.setMode('design');
                editor.theme.panel.find("toolbar").show();
            }
        }

        editorInitialized(editor) {
            editor.show();

            if (!_.isNil(this.ngModelController.$modelValue) && !_.isEmpty(this.ngModelController.$modelValue)) {
                editor.setContent(this.ngModelController.$modelValue);
            }

            this.ngModelBehaviour(editor);
            this.editorReadonly(editor, this.readonly);
        }

        ngModelBehaviour(editor) {
            editor
                .on("ExecCommand change keyup NodeChange ObjectResized", () => this.editorEventTriggered(editor))
                .on("blur", () => this.ngModelController.$setTouched())
                .on("init", () => {
                    this.ngModelController.$setPristine();
                    this.ngModelController.$setUntouched();
                });

            this.ngModelController.$render = () => {
                editor.setContent(this.ngModelController.$viewValue || "");
            };
        }

        editorEventTriggered(editor) {
            let content = editor.getContent();

            if (content.length !== 0 || !_.isEmpty(this.ngModelController.$modelValue)) {
                this.ngModelController.$setViewValue(content);
                this.ngModelController.$commitViewValue();
            }
        }
    }

    tpl.$inject = [];

    function tpl() {
        return `
            <label ng-bind="vm.label" ng-class="{'custom-color': vm.labelColor}" ng-style="{color: vm.labelColor}" ng-if="vm.label"></label>
            <textarea style="display: none;"></textarea>
        `
    }

    module.exports = {
        controller: TextEditorCtrl,
        controllerAs: "vm",
        bindings: {
            name: '@name',
            readonly: "<?",
            configKey: '@key',
            minimal: '<minimal',
            label: '@?withLabel',
            labelColor: "@?",
            options: '<',
        },
        require: {ngModelController: "ngModel"},
        template: tpl
    };
})();