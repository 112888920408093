/**
 * Created by amine on 08/09/2017.
 */

(function () {
    "use strict";

    module.exports = generateToolbar;

    const calculateFormula = require('shared/utils/calculate-formula');

    function generateToolbar(options, config, authService, configService, dateFormat) {
        let toolbar = {
            toolbar1: "",
            toolbar2: "",
            data_injector_buttons: []
        };

        if (_.isNil(options)) return toolbar;

        if (!_.get(options, "minimal", false)) {
            toolbar.toolbar1 += "editor_speech blank_line custom_variable | templates ";

            if (!options['auto_compile'] && !options.isModel) toolbar.toolbar1 += " | compile_content | ";
            else toolbar.toolbar1 += " | "

            if (options['has_patient_menu']) {
                toolbar.data_injector_buttons.push(patientMenu(options, options.patient, configService.checkActiveModule('has_parents_name')));
                toolbar.toolbar1 += "patient_editor_data_menu ";
            }

            if (options['has_measurement_menu']) {
                toolbar.data_injector_buttons.push(measurementMenu(options, options.measurements));
                toolbar.toolbar1 += "patient_measurements_editor_data_menu ";
            }

            if (options['has_physician_menu']) {
                toolbar.data_injector_buttons.push(physicianMenu(options, authService));
                toolbar.toolbar1 += "physician_editor_data_menu ";
            }

            // fire pacs patient studies sharing
            if (options['has_fire_pacs_exams_sharing'] && options.fire_pacs_studies.length > 0) {
                toolbar.data_injector_buttons.push(FirePacsStudiesMenu(options));
                toolbar.toolbar1 += "fire_pacs_exams_data_menu ";
            }

            if (options['has_patient_menu']) {
                toolbar.data_injector_buttons.push(definedBlockMenu(options, dateFormat));
                toolbar.toolbar1 += "plus_editor_data_menu ";
            }

            toolbar.data_injector_buttons.push(ReminderEmailMenu());
            toolbar.data_injector_buttons.push(VideoCallMenu());
            toolbar.data_injector_buttons.push(ConsentMenu());

            // if (options['has_patient_menu'] && configService.activeModules['has_vaccination_calendar']) {
            //     toolbar.data_injector_buttons.push(VaccinationCalendarMenu(options));
            //     toolbar.toolbar1 += "vaccination_calendar_data_menu ";
            //
            // }
        } else {
            toolbar.toolbar1 += "editor_speech blank_line | templates | ";
        }

        if (!_.isUndefined(config.data_injector_buttons)) {
            toolbar.data_injector_buttons = _.unionBy(data_injector_buttons, _.isUndefined(config.buttons_to_inject) ? [] : config.buttons_to_inject, "title");
        }

        return toolbar;
    }

    function patientMenu(editorOptions, patient, has_parents_name) {
        let menu = {
            title: "patient_editor_data",
            items: [
                {
                    title: "file_number",
                    value: getValue(patient, "file_number"),
                    key: "patient::file_number"
                },
                {
                    title: "full_name",
                    value: getValue(patient, "full_name"),
                    key: "patient::full_name"
                },
                {
                    title: "birth_date",
                    value: getValue(patient, "birth_date"),
                    key: "patient::birth_date"
                },
                {
                    title: "age",
                    value: getValue(patient, "age"),
                    key: "patient::age"
                },
                {
                    title: "address",
                    value: getValue(patient, "contact_info.address"),
                    key: "patient::address"
                },
                {
                    title: "city",
                    value: getValue(patient, "contact_info.city.full_name"),
                    key: "patient::city"
                },
                {
                    title: "phone_number",
                    value: getValue(patient, "contact_info.phone_numbers[0]"),
                    key: "patient::phone_number"
                },
                {
                    title: "profession",
                    value: getValue(patient, "profession.value"),
                    key: "patient::profession"
                },
                {
                    title: "national_id",
                    value: getValue(patient, "national_id"),
                    key: "patient::national_id"
                },
                {
                    title: "treating_physician",
                    value: getValue(patient, "external_treating_physician.full_name"),
                    key: "patient::treating_physician"
                },
                {
                    title: "file_date",
                    value: getValue(patient, "file_date"),
                    key: "patient::file_date"
                }
            ]
        };

        if (has_parents_name) {
            menu.items = _.concat(menu.items, [
                {
                    title: "father_fullname",
                    value: getValue(patient, "father_name"),
                    key: "patient::father_name"
                },
                {
                    title: "mother_fullname",
                    value: getValue(patient, "mother_name"),
                    key: "patient::mother_name"
                }
            ])
        }

        return menu;

        function getValue(patient, key) {
            if (editorOptions.isModel)
                return null
            else
                return _.bind(function () {
                    let value = _.get(this, key, "");
                    return _.isNil(value) ? "" : value;
                }, patient);
        }
    }

    function measurementMenu(editorOptions, dataMeasures) {
        let measurements = dataMeasures.measurements;
        let measures = dataMeasures.measures;

        function getValue(measurements, measure) {
            if (editorOptions.isModel || _.isNull(measurements))
                return null;
            else
                return _.bind(function (measure, measurements, measures) {
                    let unit = !_.isNull(measure.unit) ? (" " + measure.unit.value) : "";
                    let measurement = _.get(measurements, measure.id, {});
                    if (_.isEmpty(measurement) && measure.type !== "calculated") {
                        return "- " + unit;
                    } else {
                        switch (measure.type) {
                            case "integer":
                                return measurement.value.toFixed(0) + unit;
                            case "float":
                                return measurement.value.toFixed(2) + unit;
                            case "boolean":
                                return measurement.value ? $translate.instant("yes") : $translate.instant("no");
                            case "calculated":
                                const calculatedValue = calculateFormula(measure["formula"], measurements, measures);
                                return `${calculatedValue ? calculatedValue.toFixed(2) : "-"} ${unit}`;
                            default:
                                return measurement.value + unit;
                        }
                    }
                }, {}, measure, measurements, measures);
        }

        let items = _.reduce(measures, function (result, value) {
            result.push({
                title: value.name,
                value: getValue(measurements, value),
                key: "measurements::measure" + value.id
            })

            return result;
        }, []);

        return {
            title: "patient_measurements_editor_data", items
        }
    }

    function physicianMenu(editorOptions, authService) {
        let physician = _.get(authService, "staff", false);

        function getValue(physician, key) {
            if (editorOptions.isModel)
                return null;
            else
                return _.bind(function () {
                    return _.get(this, key, "");
                }, physician);
        }

        return {
            title: "physician_editor_data",
            items: [
                {
                    title: "full_name",
                    value: getValue(physician, "full_name"),
                    key: "physician::full_name"
                }
            ]
        }
    }

    function definedBlockMenu(editorOptions, dateFormat) {
        function currentDate() {
            return window.moment().format(dateFormat);
        }

        function getVaccinations(vaccinations) {
            return _.bind(function () {
                let $el = $("<div />").append($("<ul />"));
                _.forEach(this.vaccinations, function (value) {
                    const li = `<li>
                        <span style='text-decoration: underline;font-weight: bold;'>${value['vaccine_name']}: ${value['vaccination_date']}(${value['age_at_vaccination']})</span>
                    </li>`;

                    $("ul", $el).append(li);
                });

                return $el.html();
            }, {vaccinations})
        }

        return {
            title: "plus_editor_data",
            items: [
                {
                    title: "date",
                    value: currentDate,
                    key: "misc::date"
                },
                {
                    title: "patient_vaccination",
                    value: getVaccinations(editorOptions.vaccinations),
                    key: "defined::vaccinations"
                }
            ]
        }
    }

    function ReminderEmailMenu() {
        return {
            title: "reminder_email",
            items: [
                {
                    title: "full_name",
                    value: null,
                    key: "patient::fullname"
                },
                {
                    title: "appointment_date",
                    value: null,
                    key: "appointment::date"
                },
                {
                    title: "appointment_time",
                    value: null,
                    key: "appointment::time"
                }
            ]
        }
    }

    function VideoCallMenu() {
        return {
            title: "video_call",
            items: [
                {
                    title: "full_name",
                    value: null,
                    key: "patient_full_name"
                },
                {
                    title: "physician_name",
                    value: null,
                    key: "physician_full_name"
                },
                {
                    title: "video_call_url",
                    value: null,
                    key: "video_call_url"
                },
                {
                    title: "video_call_password",
                    value: null,
                    key: "video_call_password"
                }
            ]
        }
    }

    function ConsentMenu() {
        return {
            title: "consent",
            items: [
                {
                    title: "full_name",
                    value: null,
                    key: "patient::fullname"
                },
                {
                    title: "birth_date",
                    value: null,
                    key: "patient::birth_date"
                },
                {
                    title: "age",
                    value: null,
                    key: "patient::age"
                },
                {
                    title: "|"
                },
                {
                    title: "signature",
                    value: null,
                    key: "document::signature"
                },
                {
                    title: "signature_date",
                    value: null,
                    key: "document::signature_date"
                },
            ]
        }
    }

    // function VaccinationCalendarMenu(editorOptions) {
    //     function getVaccinations(calendar) {
    //         console.log(calendar);
    //
    //         return "-";
    //     }
    //
    //     return {
    //         title: "vaccination_calendar_data",
    //         items: [
    //             {
    //                 title: "vaccination_calendar_done",
    //                 value: getVaccinations(editorOptions.vaccination_calendar), //getVaccinations(editorOptions.vaccinations),
    //                 key: "vaccination_calendar::current"
    //             }
    //         ]
    //     }
    // }


    function FirePacsStudiesMenu(options) {
        const items = _.reduce(options.fire_pacs_studies, (result, value) => {
            const item = {
                title: value.study_description,
                items: [
                    {
                        title: 'fire_pacs_exams_study_url',
                        key: `${value.password}::url`,
                        value: () => value.external_url
                    },
                    {
                        title: 'fire_pacs_exams_study_qr_code',
                        key: '`${value.password}::qr`',
                        value: () => value.qr_code,
                        isImage: true
                    },
                    {
                        title: 'fire_pacs_exams_study_password',
                        key: '`${value.password}::password`',
                        value: () => value.password
                    }
                ]
            }

            return _.concat(result, item);
        }, []);

        return {
            title: 'fire_pacs_exams_data', items
        }
    }
})();
